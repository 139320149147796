.feature {
  height: 100%;
  border: 1px solid $soma-white-alt;
  border-radius: 3px;
  padding: 20px;
  background-color: $soma-white;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  .feature-image {
    flex: 0 0 auto;
    width: 80px;
    height: 80px;
    display: flex;
    margin-bottom: 20px;
    text-align: center;
  }
  img {
    width: 100%;
    height: auto;
  }
  .feature-title {
    color: $soma-black;
  }
  .feature-content {
    color: $soma-black;
    margin-bottom: 0;
    margin-top: auto;
  }
}
