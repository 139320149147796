.intro-image {
  width: 100%;
  height: auto;
  margin-top: -40px;
  border-radius: 4px;
}
.intro-image-absolute {
  max-width: none;

  padding: 0;
  margin-bottom: 30px;
  @include media-breakpoint-up(sm) {
  }
  @include media-breakpoint-up(md) {
    position: absolute;
    bottom: -50px;
    left: -50px;
    width: 700px;
  }
  @include media-breakpoint-up(lg) {
    bottom: -60px;
    left: -60px;
    width: 800px;
  }
  @include media-breakpoint-up(xl) {
    bottom: -100px;
    left: -70px;
    width: 850px;
  }
}
.intro-image-hide-mobile {
  display: none;
  @include media-breakpoint-up(md) {
    display: block;
  }
}
